<template>
  <v-row no-gutters>

    <!-- Lang -->
    <v-col cols="12">
      <div class="subheading font-weight-bold ml-4">
        <span class="detail-text-label">{{$t('profile.preferences.labels.lang')}} : </span>
        <span class="detail-text-value">{{langData[preferences.lang] ? langData[preferences.lang]['native'] : $tc('base.misc.NA', 2)}}</span>
      </div>
    </v-col>

    <!-- Country -->
    <v-col cols="12" class="mt-2">
      <div class="subheading font-weight-bold ml-4">
        <span class="detail-text-label">{{$t('profile.preferences.labels.country')}} : </span>
        <span class="detail-text-value">{{countriesData[preferences.country] ? countriesData[preferences.country]['name'] : $tc('common.misc.NA', 1)}}</span>
      </div>
    </v-col>

    <!-- Registration date --
    <v-col cols="12" class="mt-2">
      <div class="subheading font-weight-bold ml-4">
        <span class="detail-text-label">{{$t('profile.preferences.labels.registration_date')}} </span>
        <span class="detail-text-value">{{registration_date ? this.$moment(registration_date).format("DD/MM/YYYY") : ""}}</span>
      </div>
    </v-col-->

    <!-- Timezone --
    <v-col cols="12" class="mt-2">
      <div class="subheading font-weight-bold ml-4">
        <span class="detail-text-label">{{$t('profile.labels.timezone')}} : </span>
        <span class="detail-text-value">{{preferences.timezone}}</span>
      </div>
    </v-col-->
  </v-row>
</template>

<script>

  import langData from '@/translations/constants/languages.json'
  import countriesData from '@/translations/constants/countries.json'
  import formatter from "@/mixins/formatter"

  export default {
    name: "ProfilePreferences",

    props: {
      preferences: {type: Object, default: () => null},
      registration_date: {type: String, default: null}
    },

    mixins: [formatter],

    data()
    {
      return {
        langData,
        countriesData
      }
    }
  }
</script>

<style scoped>

</style>